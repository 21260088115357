




















































































































































































































































































































import {
    Component,
    Vue
} from 'vue-property-decorator';
import {
    getComponent
} from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import AWS from 'aws-sdk';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';

import {
    POST_SHAPEFILE_LAMBDA_NAME,
    POST_SHAPEFILE_LAMBDA_REGION,
    IDENTITY_POOL_REGION,
    IDENTITY_POOL_ID,
    SHAPEFILE_BUCKET_NAME,
    SHAPEFILE_BUCKET_REGION,
    MAPBOX_ACCESS_TOKEN,
} from '@/lib/constants';



@Component({
    components: {
        UserSettings: () => getComponent('common/UserSettings'),
    },
})
export default class UserSchedule extends Vue {
    addRulePopupShowing = false
    showScheduleRulesPopup = false
    showScheduleRulesContent = false
    showScheduleVacationContent = false
    showRuleCreation = false
    ruleEnabledToggle = true
    canCompleteRule = false
    savingCreateNewRule = false
    hoveringOverElement = null
    ruleCreationResponseDict = {}
    ruleCreationResponseOptionsList = {}
    ruleCreationStringInnerHTML = ''
    currentDate = ''
    currentWorkflowOption = 1
    viewTotalDays = 30
	newRuleOnOffStartDate = ''
	addRuleDaysOnSelection = 1
	addRuleDaysOffSelection = 1
    loadingGanttChart = false
	showAddRuleCreationPopupLoading = true
	deleteRuleLoading = false
	deleteRuleMenuShowing = false
    ruleWorkflowOptions = {
        "1": {
            "is_initial_selection": true,
            "wording":"Assign {{}}",
            "placeholder": "what user?",
            "allow_multiple_selection": false,
            "can_complete_rule": false,
            "selection_type": "String",
            "selection_category": "USER_ASSIGNMENT_SELECTION",
            "selection_options": {
            }
        },
        "2": {
            "is_initial_selection": false,
            "wording":" to {{}} ",
            "placeholder": "what route?",
            "allow_multiple_selection": false,
            "can_complete_rule": false,
            "selection_type": "String",
            "selection_category": "ROUTE_SELECTION",
            "selection_options": {
            }
        },
        "3": {
            "is_initial_selection": false,
            "wording":" {{}} ",
            "placeholder": "when?",
            "allow_multiple_selection": false,
            "can_complete_rule": false,
            "selection_type": "Condition",
            "selection_category": "CONDITION_SELECTION",
            "selection_options": {
                "1":{
                    "selection_name": "every",
                    "selection_id": "1",
                    "allow_multiple_selection": false,
                    "selected": false,
                    "link_to_id": "4"
                },
                "2":{
                	"selection_name": "on/off schedule",
                	"selection_id": "2",
                	"allow_multiple_selection": false,
                	"selected": false,
                	"link_to_id": "10"
                },
                // "3":{
                //     "selection_name": "if",
                //     "selection_id": "3",
                //     "allow_multiple_selection": false,
                //     "selected": false,
                //     "link_to_id": "5"
                // },
                // "4":{
                // 	"selection_name": "from",
                // 	"selection_id": "4",
                // 	"allow_multiple_selection": false,
                // 	"selected": false,
                // 	"link_to_id": "5"
                // },
                // "5":{
                // 	"selection_name": "for shift",
                // 	"selection_id": "5",
                // 	"allow_multiple_selection": false,
                // 	"selected": false,
                // 	"link_to_id": "5"
                // },
            }
        },
        "4": {
            "is_initial_selection": false,
            "wording":"{{}}",
            "placeholder": "(select days)",
            "selection_type": "String",
            "selection_category": "DAY_OF_WEEK_SELECTION",
            "can_complete_rule": true,
            "allow_multiple_selection": true,
            "selection_options": {
                "1":{
                    "selection_name": "Monday",
                    "selection_id": "monday",
                    "allow_multiple_selection": true,
                    "selected": false,
                    "link_to_id": null
                },
                "2":{
                    "selection_name": "Tuesday",
                    "selection_id": "tuesday",
                    "allow_multiple_selection": true,
                    "selected": false,
                    "link_to_id": null
                },
                "3":{
                    "selection_name": "Wednesday",
                    "selection_id": "wednesday",
                    "allow_multiple_selection": true,
                    "selected": false,
                    "link_to_id": null
                },
                "4":{
                    "selection_name": "Thursday",
                    "selection_id": "thursday",
                    "allow_multiple_selection": true,
                    "selected": false,
                    "link_to_id": null
                },
                "5":{
                    "selection_name": "Friday",
                    "selection_id": "friday",
                    "allow_multiple_selection": true,
                    "selected": false,
                    "link_to_id": null
                },
                "6":{
                    "selection_name": "Saturday",
                    "selection_id": "saturday",
                    "allow_multiple_selection": true,
                    "selected": false,
                    "link_to_id": null
                },
                "7":{
                    "selection_name": "Sunday",
                    "selection_id": "sunday",
                    "allow_multiple_selection": true,
                    "selected": false,
                    "link_to_id": null
                }
            }
        },
        // "9": {
        // 	"is_initial_selection": false,
        // 	"wording":"{{}}",
        // 	"placeholder": "(select days)",
        // 	"selection_type": "String",
        // 	"selection_category": "EVERY_OTHER_DAY_OF_WEEK_SELECTION",
        // 	"can_complete_rule": true,
        // 	"allow_multiple_selection": true,
        // 	"selection_options": {
        // 		"1":{
        // 			"selection_name": "Monday",
        // 			"selection_id": "monday",
        // 			"allow_multiple_selection": true,
        // 			"selected": false,
        // 			"link_to_id": null
        // 		},
        // 		"2":{
        // 			"selection_name": "Tuesday",
        // 			"selection_id": "tuesday",
        // 			"allow_multiple_selection": true,
        // 			"selected": false,
        // 			"link_to_id": null
        // 		},
        // 		"3":{
        // 			"selection_name": "Wednesday",
        // 			"selection_id": "wednesday",
        // 			"allow_multiple_selection": true,
        // 			"selected": false,
        // 			"link_to_id": null
        // 		},
        // 		"4":{
        // 			"selection_name": "Thursday",
        // 			"selection_id": "thursday",
        // 			"allow_multiple_selection": true,
        // 			"selected": false,
        // 			"link_to_id": null
        // 		},
        // 		"5":{
        // 			"selection_name": "Friday",
        // 			"selection_id": "friday",
        // 			"allow_multiple_selection": true,
        // 			"selected": false,
        // 			"link_to_id": null
        // 		},
        // 		"6":{
        // 			"selection_name": "Saturday",
        // 			"selection_id": "saturday",
        // 			"allow_multiple_selection": true,
        // 			"selected": false,
        // 			"link_to_id": null
        // 		},
        // 		"7":{
        // 			"selection_name": "Sunday",
        // 			"selection_id": "sunday",
        // 			"allow_multiple_selection": true,
        // 			"selected": false,
        // 			"link_to_id": null
        // 		}
        // 	}
        // },
        "5": {
            "is_initial_selection": false,
            "wording":"{{}} is out",
            "placeholder": "(user)",
            "selection_type": "String",
            "can_complete_rule": true,
            "allow_multiple_selection": false,
            "selection_category": "USER_REPLACEMENT_SELECTION",
            "selection_options": {
                "1": {
                    "selection_name": "original assignee",
                    "selection_id": "john@email.com",
                    "selected": false,
                    "link_to_id": null
                },
                "2": {
                    "selection_name": "bill",
                    "selection_id": "bill@email.com",
                    "selected": false,
                    "link_to_id": null
                }
            }
        },
        "10": {
            "is_initial_selection": false,
            "wording":" - {{}}",
            "placeholder": "select days on/off",
            "selection_type": "Object",
            "can_complete_rule": true,
            "allow_multiple_selection": false,
			"selection_category": "DAYS_ON_OFF_SELECTION",
			"link_to_id": null
        },
        "11": {
            "is_initial_selection": false,
            "wording":" and {{}} days off",
            "placeholder": "select",
            "selection_type": "Number",
            "can_complete_rule": false,
            "allow_multiple_selection": false,
			"selection_category": "DAYS_OFF_SELECTION",
			"link_to_id": "12"
        },
        "12": {
            "is_initial_selection": false,
            "wording":" starting {{}}",
            "placeholder": "select",
            "selection_type": "Number",
            "can_complete_rule": true,
            "allow_multiple_selection": false,
			"selection": null,
			"selection_category": "DAYS_ON_OFF_CALENDAR_SELECTION",
			"link_to_id": null
        },
        "6": {
            "is_initial_selection": false,
            "wording":" from {{}}",
            "placeholder": "Select a user",
            "selection_type": "Calendar",
            "selection_category": "CALENDAR_SELECTION",
            "allow_multiple_selection": false,
            "link_to_id": "7"
        },
        "7": {
            "is_initial_selection": false,
            "wording":" to {{}}",
            "placeholder": "Select a user",
            "selection_type": "Calendar",
            "selection_category": "CALENDAR_SELECTION",
            "can_complete_rule": true,
            "allow_multiple_selection": false,
            "link_to_id": null
        },
        "8": {
            "is_initial_selection": false,
            "wording":" to {{}}",
            "placeholder": "Select a shift",
            "selection_type": "Calendar",
            "selection_category": "SHIFT_SELECTION",
            "can_complete_rule": true,
            "allow_multiple_selection": false,
            "link_to_id": null
        }

    }

    routes: any[] = []
    dates: any[] = []

	addRuleDaysOnOffSelectionDone() {
				

		this.ruleWorkflowOptions[this.currentWorkflowOption].selection = {
			"daysOn": this.addRuleDaysOnSelection,
			"daysOff": this.addRuleDaysOffSelection,
			"startDate": this.newRuleOnOffStartDate
		}
		this.currentWorkflowOption = this.ruleWorkflowOptions[this.currentWorkflowOption].link_to_id
		this.hoveringOverElement = null
		this.setupRuleCreationString()

	}


    get getCurrentName(): string {
		return getNameByEmail(accountModule.user.email);
	}

    get activeRulesCount(): any {
        return scheduleModule.scheduleRules.length
    }

    get scheduleComponents(): any {
        return scheduleModule.scheduleComponents
    }

    get scheduleRules(): any {
        var return_results: any[] = []
        for (var x = 0; x < scheduleModule.scheduleRules.length; x++) {
            
            scheduleModule.scheduleRules[x].RuleString = this.concatenateRuleCreationString(scheduleModule.scheduleRules[x].RuleConditionsDict, false)
            return_results.push(scheduleModule.scheduleRules[x])
        }
        return return_results;
    }


    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    async created() {
		const operator = getConfigEnv('OPERATOR_LOWERCASED');

        this.loadPage()
		this.showAddRuleCreationPopupLoading = true
		await accountModule.getReassignmentList()
		await scheduleModule.getEnabledRoutes()
		this.showAddRuleCreationPopupLoading = false
		this.setupRulesWithUsers()
		this.setupRulesWithRoutes()

    }

	
	async deleteRuleButtonClicked(scheduleRule) {

		this.deleteRuleLoading = scheduleRule.ID
		await scheduleModule.deleteRule({
			rule_id: scheduleRule.ID
		})
		this.deleteRuleLoading = true
		location.reload()


	}

	get currentWorkflowObject(): any[] {
		return this.ruleWorkflowOptions[this.currentWorkflowOption]
	}
	

	get ruleSelectionOptions(): any[] {
		var results: any[] = []
		if (this.currentWorkflowOption != null) {
			for (var key in this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options){
				results.push({
					"key": key,
					"allow_multiple_selection": this.ruleWorkflowOptions[this.currentWorkflowOption].allow_multiple_selection,
					"selection_name": this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options[key]["selection_name"],
					"selection_id": this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options[key]["selection_id"],
					"selected": this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options[key]["selected"],
					"link_to_id": this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options[key]["link_to_id"]
				})
			}
		}


		return results
	}


















	deleteRuleMenuShowingToggle(rule_id) {
		if (rule_id == this.deleteRuleMenuShowing) {
			this.deleteRuleMenuShowing = false
		} else {
			this.deleteRuleMenuShowing = rule_id
		}
	}


	setupRulesWithRoutes() {
		var selectionOptions = {}

		var addedRoutes: any[] = []
		for (var x = 0; x < scheduleModule.enabledRoutes.length; x++) {
			var routeObject = scheduleModule.enabledRoutes[x]
			// Make sure we haven't already added this route
			if (!(addedRoutes.indexOf(routeObject.Route) > -1)) {
				selectionOptions[x] = {
					"selection_name": routeObject.Route,
					"selection_id": routeObject.RouteID,
					"allow_multiple_selection": false,
					"selected": false,
					"link_to_id": "3"
				}
			}

		}
		this.ruleWorkflowOptions["2"]['selection_options'] = {}
		Vue.set(this.ruleWorkflowOptions["2"], 'selection_options', selectionOptions);
		// this.ruleWorkflowOptions["2"]["selection_options"] = selectionOptions
	}


	setupRulesWithUsers() {

		var selectionOptions = {}
		for (var x = 0; x < accountModule.reassignmentList.length; x++) {
			var reassignmentObject = accountModule.reassignmentList[x]
			if (reassignmentObject.first_name == null || reassignmentObject.last_name == null) {
				continue
			}
			selectionOptions[x] = {
				"selection_name": reassignmentObject.first_name! + " " + reassignmentObject.last_name!,
				"selection_id": reassignmentObject.email,
				"selected": false,
				"link_to_id": "2"
			}
		}
		// @ts-ignore
		// this.ruleWorkflowOptions["1"]["selection_options"] = selectionOptions
		this.ruleWorkflowOptions["1"]['selection_options'] = {}
		Vue.set(this.ruleWorkflowOptions["1"], 'selection_options', selectionOptions);
		// var copy = {...this.ruleWorkflowOptions};
		// // @ts-ignore
		// copy["1"]["selection_options"] = selectionOptions
		// this.ruleWorkflowOptions = {...copy};
	}

    async loadPage() {
        this.loadingGanttChart = true

		

        this.currentDate = this.pad((new Date().getMonth() + 1), 2, '0') + "/" + this.pad((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
        var start_datetime = new Date(new Date().setHours(new Date().getHours() - 24))
        start_datetime = new Date(start_datetime.setHours(0))
        start_datetime = new Date(start_datetime.setMinutes(0))
        start_datetime = new Date(start_datetime.setSeconds(0))

        var end_datetime = new Date(new Date().setHours(start_datetime.getHours() + (this.viewTotalDays * 24)))
        end_datetime = new Date(end_datetime.setHours(0))
        end_datetime = new Date(end_datetime.setMinutes(0))
        end_datetime = new Date(end_datetime.setSeconds(0))
        
        var start_date_string = start_datetime.getUTCFullYear() + "-" + this.pad(start_datetime.getUTCMonth() + 1, 2, '0') + "-" + this.pad(start_datetime.getUTCDate(), 2, '0') + "T" + this.pad(start_datetime.getUTCHours(), 2, '0') + ":" + this.pad(start_datetime.getUTCMinutes(), 2, '0')+ ":" + this.pad(start_datetime.getUTCSeconds(), 2, '0')
        var end_date_string = end_datetime.getUTCFullYear() + "-" + this.pad(end_datetime.getUTCMonth() + 1, 2, '0') + "-" + this.pad(end_datetime.getUTCDate(), 2, '0') + "T" + this.pad(end_datetime.getUTCHours(), 2, '0') + ":" + this.pad(end_datetime.getUTCMinutes(), 2, '0')+ ":" + this.pad(end_datetime.getUTCSeconds(), 2, '0')

        await scheduleModule.getScheduleRulesAndComponents({
            from_date: start_date_string,
            to_date: end_date_string
        })
        this.routes = []
        this.dates = []
        for (var key in this.scheduleComponents) {
            
            this.routes.push({
                "RouteName": this.scheduleComponents[key].route.selection_name,
                "RouteID": key,
                "RouteElementID": key.replace(" ", "_").toLowerCase(),
                "DateTimes": []
            })
        }


        // Show chart 30 days
        for (var days = 0; days < (this.viewTotalDays + 1); days++) {
            var day_datetime = new Date(start_datetime)
            day_datetime = new Date(day_datetime.setDate(start_datetime.getDate() + days))
            var hours_array: Date[] = []
            var day_object = {
                "day_id": day_datetime.toString(),
                "day_short": day_datetime.getDate(),
                "day": day_datetime,
                "hours": hours_array
            }
            for (var hours = 0; hours < 24; hours++) {
                var hour_datetime = new Date(day_datetime)
                hour_datetime = new Date(hour_datetime.setHours(day_datetime.getHours() + hours))
                day_object.hours.push(hour_datetime)
            }
            this.dates.push(day_object)
        }
        for (var x = 0; x < this.routes.length; x++) {
            this.routes[x].DateTimes = this.dates
        }
        this.loadingGanttChart = false
        sleep(100).then(() => {
            
            loadScheduleComponentsOnGanttChart(this.scheduleRules, this.routes, this.scheduleComponents, start_datetime, end_datetime)
            
        });
        
    }


    resetRuleWorkflowOptions() {
        for (var key in this.ruleWorkflowOptions) {
            for (var selection_option_key in this.ruleWorkflowOptions[key].selection_options) {
                this.ruleWorkflowOptions[key].selection_options[selection_option_key].selected = false
            }
        }
    }

    resetRuleCreationPage() {
        this.addRulePopupShowing = false
        this.showScheduleRulesPopup = false
        this.showScheduleRulesContent = false
        this.showScheduleVacationContent = false
        this.showRuleCreation = false
        this.ruleEnabledToggle = true
        this.canCompleteRule = false
        this.savingCreateNewRule = false
        this.hoveringOverElement = null
        this.ruleCreationResponseDict = {}
        this.ruleCreationResponseOptionsList = {}
        this.ruleCreationStringInnerHTML = ''
        this.currentWorkflowOption = 1
        this.resetRuleWorkflowOptions()
    }


    async postNewRule() {

        var start_datetime = new Date(new Date().setHours(new Date().getHours() - 24))
        start_datetime = new Date(start_datetime.setHours(0))
        start_datetime = new Date(start_datetime.setMinutes(0))
        start_datetime = new Date(start_datetime.setSeconds(0))

        var end_datetime = new Date(new Date().setHours(start_datetime.getHours() + (this.viewTotalDays * 24)))
        end_datetime = new Date(end_datetime.setHours(0))
        end_datetime = new Date(end_datetime.setMinutes(0))
        end_datetime = new Date(end_datetime.setSeconds(0))
        
        var start_date_string = start_datetime.getUTCFullYear() + "-" + this.pad(start_datetime.getUTCMonth() + 1, 2, '0') + "-" + this.pad(start_datetime.getUTCDate(), 2, '0') + "T" + this.pad(start_datetime.getUTCHours(), 2, '0') + ":" + this.pad(start_datetime.getUTCMinutes(), 2, '0')+ ":" + this.pad(start_datetime.getUTCSeconds(), 2, '0')
        var end_date_string = end_datetime.getUTCFullYear() + "-" + this.pad(end_datetime.getUTCMonth() + 1, 2, '0') + "-" + this.pad(end_datetime.getUTCDate(), 2, '0') + "T" + this.pad(end_datetime.getUTCHours(), 2, '0') + ":" + this.pad(end_datetime.getUTCMinutes(), 2, '0')+ ":" + this.pad(end_datetime.getUTCSeconds(), 2, '0')


        this.savingCreateNewRule = true
        await scheduleModule.postNewUserScheduleRule({
            username: this.getCurrentName.toLowerCase(),
            rule_conditions_dict: JSON.stringify(this.ruleWorkflowOptions)
        })
        this.savingCreateNewRule = false
        this.resetRuleCreationPage()
        await scheduleModule.getScheduleRulesAndComponents({
            from_date: start_date_string,
            to_date: end_date_string
        })
        this.loadPage()
    }

    createNewRule() {

        if (this.canCompleteRule) {
            this.postNewRule()

        }
    }


    ruleCreationMultipleSelect(responseKey, responseOption) {
            
        var check_value = !this.ruleCreationResponseOptionsList[responseKey].selected
        this.ruleCreationResponseOptionsList[responseKey].selected = check_value
        this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options[responseKey].selected = check_value
        
        this.setupRuleCreationString()

        
    }


    mouseOverSelectedRuleCreation(responseKey, responseOption){
        this.hoveringOverElement = responseOption.selection_name
        this.setupRuleCreationString()
    }
    mouseLeaveSelectedRuleCreation(responseKey, responseOption) {
        this.setupRuleCreationString()
        this.hoveringOverElement = null
    }

    selectedRuleCreationResponseOption(id, response_option_dict) {
        
        this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options[id].selected = true
        this.currentWorkflowOption = response_option_dict.link_to_id
        this.hoveringOverElement = null
        this.setupRuleCreationString()
		// this.setupRulesWithRoutes()
		// this.setupRulesWithUsers()

    }

    concatenateRuleCreationString(ruleWorkflowOptions, addPlaceholders = true) {

        var ruleHtmlString = ''
        for (var key in ruleWorkflowOptions){
            var response_string = ''
			if (ruleWorkflowOptions[key].selection_category == "DAYS_ON_OFF_SELECTION") {
				if (ruleWorkflowOptions[key].selection == null) {
					continue
				}
			// "daysOn": this.addRuleDaysOnSelection,
			// "daysOff": this.addRuleDaysOffSelection,
			// "startDate": this.newRuleOnOffStartDate
			
			response_string = ruleWorkflowOptions[key].selection["daysOn"] + " on " + ruleWorkflowOptions[key].selection["daysOff"] + " off " + " starting " + ruleWorkflowOptions[key].selection["startDate"]
			
			} else {
				for (var selection_key in ruleWorkflowOptions[key].selection_options) {
					if (!ruleWorkflowOptions[key].selection_options[selection_key].selected) {
						continue
					}
					if (response_string != '') {
						response_string = response_string + ", "
					}
					response_string = response_string + ruleWorkflowOptions[key].selection_options[selection_key].selection_name
					
				}

			}

			if (response_string != '') {
				var wording = ruleWorkflowOptions[key].wording
				wording = wording.replace("{{}}", '<span style="opacity: 1; font-weight: 600;">' + response_string + '</span>')
				ruleHtmlString = ruleHtmlString + wording
			}


        
        }
        if (this.currentWorkflowOption != null && addPlaceholders) {
            var current_workflow_option = ruleWorkflowOptions[this.currentWorkflowOption]

            if (current_workflow_option.can_complete_rule) {
                this.canCompleteRule = true
            } else {
                this.canCompleteRule = false
            }
            var wording = current_workflow_option.wording
            if (this.hoveringOverElement != null) {
                wording = wording.replace("{{}}", '<span style="opacity: 0.6;">' + this.hoveringOverElement + '</span>')
            } else if (current_workflow_option.allow_multiple_selection) {
                var item_is_checked = false
                for (var selection_key in current_workflow_option.selection_options) {
                    
                    if (!current_workflow_option.selection_options[selection_key].selected) {
                        continue
                    }
                    item_is_checked = true
                    break

                }
                if (!item_is_checked) {
                    wording = wording.replace("{{}}", '<span style="opacity: 0.6;">' + current_workflow_option.placeholder + '</span>')
                } else {
                    wording = wording.replace("{{}}", '')
                }
                
            } else if (current_workflow_option.selection_category == "DAYS_ON_OFF_SELECTION") {
				wording = wording.replace("{{}}", '<span style="cursor: pointer; opacity: 0.6; border-bottom: 2px solid rgba(255,255,255,1);">' + current_workflow_option.placeholder + '</span>')
			} else {
                wording = wording.replace("{{}}", '<span style="opacity: 0.6;">' + current_workflow_option.placeholder + '</span>')
            }
            
            ruleHtmlString = ruleHtmlString + wording
        }
        return ruleHtmlString
    }




    getInitialRuleDict() {
        for (var key in this.ruleWorkflowOptions) {
            if (this.ruleWorkflowOptions[key].is_initial_selection) {
                return this.ruleWorkflowOptions[key]
            }
        }
    }


    setupRuleCreationResponseOptions() {
        if (this.currentWorkflowOption != null) {
            if (this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options != null) {
                this.ruleCreationResponseOptionsList = this.ruleWorkflowOptions[this.currentWorkflowOption].selection_options
            }
            
        }

	}



    setupRuleCreationString() {

        this.ruleCreationStringInnerHTML = this.concatenateRuleCreationString(this.ruleWorkflowOptions)
        this.setupRuleCreationResponseOptions()


    }

    


    ruleEnabledToggleChanged() {
        console.log("Rule was toggled")
    }

    showRuleCreationBackClicked() {
        this.showRuleCreation = false
        this.showScheduleRulesPopup = true
        this.showScheduleVacationContent = false
        this.showScheduleRulesContent = true
    }

    async addNewRuleButtonClicked() {

        this.showRuleCreation = true
        this.showScheduleRulesPopup = false
        this.showScheduleVacationContent = false
        this.showScheduleRulesContent = false
        this.ruleCreationStringInnerHTML = ''
        this.currentWorkflowOption = 1
        this.resetRuleWorkflowOptions()
        this.setupRuleCreationString()


		
		// this.setupRulesWithUsers()
		// this.setupRulesWithRoutes()

    }

    showScheduleVacationContentClicked() {
        this.showScheduleVacationContent = true
        this.showScheduleRulesContent = false
    }

    showScheduleRulesContentClicked() {
        this.showScheduleVacationContent = false
        this.showScheduleRulesContent = true
    }
    
	
    async showAddRuleCreationPopup() {
        this.addRulePopupShowing = true; 
        this.showScheduleRulesPopup = true;
        this.showScheduleRulesContent = true;

		
    }
    hideAddRuleCreationPopup() {
        this.addRulePopupShowing = false; 
        this.showScheduleRulesPopup = false;
        this.showRuleCreation = false;
    }

    

}



function parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0]-1, mdy[1]);
}

function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


// test_color
var SCHEDULE_COMPONENT_COLORS = ["#b05ffc", "#fcc55f", "#51cea8", "#e9a284", "#516ae9", "#878891"]
var SCHEDULE_COMPONENT_USER_COLORS = {}

function loadScheduleComponentsOnGanttChart(scheduleRules, routes, scheduleComponents, chart_start_datetime, chart_end_datetime) {

    var CHART_DAY_WIDTH = 110
    var IN_ROUTE_HEIGHT = 80

    for (var key in scheduleComponents) {
        var route = scheduleComponents[key].route
        var rows = scheduleComponents[key].rows
        var route_element_id = scheduleComponents[key].route.selection_id.replace(" ", "_").toLowerCase()

        var route_row_container_id = 'route_component_row_' + route_element_id
        var route_row_id = 'route_row_' + route_element_id
        var route_row_title_id = 'route_row_title_' + route_element_id
        var element = document.getElementById(route_row_container_id);
        if (typeof(element) != 'undefined' && element != null)
        {
            document.getElementById(route_row_container_id)!.innerHTML = ''
        } else {

        }

        var route_row_height = (IN_ROUTE_HEIGHT * (rows.length - 1)) + 150;
        document.getElementById(route_row_id)!.style.minHeight = route_row_height.toString() + "px";
        document.getElementById(route_row_title_id)!.style.minHeight = route_row_height.toString() + "px";

        // Loop through and add rows to a route row
        for (var x = 0; x < rows.length; x++) {
            var row = rows[x]
            
            var row_height = IN_ROUTE_HEIGHT.toString() + "px"
            if ((rows.length - 1) == x) {
                row_height = "150px"
            }
            let row_div = document.createElement("div")
            row_div.style.position = "relative"
            row_div.style.height = row_height
            // row_div.style.width = "100%"
            row_div.style.float = "left"
            row_div.style.clear = "both"
            row_div.style.backgroundColor = "purple"
            // row_div.style.overflow = "hidden"


            for (var y = 0; y < row.components.length; y++) {
                var component = row.components[y]
                    let endDate = new Date(component.end_date).setDate((new Date(component.end_date)).getDate() + 1)
                var days_diff = datediff(chart_start_datetime, new Date(component.start_date))
                var width_days_diff = datediff(new Date(component.start_date), new Date(endDate))


                var color = "#51cea8"
                if (SCHEDULE_COMPONENT_USER_COLORS[component.assignee.selection_name] != null) {
                    color = SCHEDULE_COMPONENT_USER_COLORS[component.assignee.selection_name]
                } else {
                    color = SCHEDULE_COMPONENT_COLORS[Object.keys(SCHEDULE_COMPONENT_USER_COLORS).length % SCHEDULE_COMPONENT_COLORS.length]
                    SCHEDULE_COMPONENT_USER_COLORS[component.assignee.selection_name] = color
                }

                var component_div = document.createElement("div")
                // component_div.classList.add("shadow-lg");
                // component_div.classList.add("rounded");
                component_div.style.boxShadow = '0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.15)'
                component_div.style.borderRadius = "8px"
                component_div.style.position = "absolute"
                component_div.style.width = (width_days_diff * CHART_DAY_WIDTH).toString() + "px"
                component_div.style.height = "50px"
                component_div.style.background = color
                component_div.style.left = (days_diff * CHART_DAY_WIDTH).toString() + "px"
                component_div.style.top = "10px"
                component_div.style.overflow = "hidden"
                // component_div.style.cursor = "move"
                // dragElement(component_div);
                


                var component_title_p = document.createElement("p")
                component_title_p.style.position = "relative"
                component_title_p.style.color = "white"
                component_title_p.style.marginTop = "4px"
                component_title_p.style.marginLeft = "10px"
                component_title_p.style.fontSize = "17px"
                component_title_p.style.fontWeight = "900"
                component_title_p.style.width = "calc(100% - 20px)"
                component_title_p.innerHTML = component.assignee.selection_name
            
                var component_sub_title_p = document.createElement("p")
                component_sub_title_p.style.position = "relative"
                component_sub_title_p.style.color = "white"
                component_sub_title_p.style.marginTop = "0px"
                component_sub_title_p.style.marginLeft = "10px"
                component_sub_title_p.style.fontSize = "13px"
                component_sub_title_p.style.fontWeight = "300"
                component_title_p.style.width = "calc(100% - 20px)"
                component_sub_title_p.innerHTML = component.start_date.replace("T"," ").replace("00:00:00","").replace("2021-","") + " - " + component.end_date.replace("T"," ").replace("00:00:00","").replace("2021-","")

                component_div.appendChild(component_title_p)
                component_div.appendChild(component_sub_title_p)

                
                
                row_div.appendChild(component_div)
                document.getElementById(route_row_container_id)!.appendChild(row_div)
                
            }

            
        
        }

    }
}


function dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id)) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id)!.onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement(e) {
    /* stop moving when mouse button is released:*/
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = null;
    document.onmousemove = null;
  }
}


